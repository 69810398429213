
import {
  Component,
  Vue
} from 'vue-property-decorator'
  @Component({
    name: 'PortDashboard',
    components: {}
  })

export default class PortDashboard extends Vue {
    protected isDecodedValue = false
    protected quantity: any = null
    protected decodedValue: any = null
    protected content: any = null
}

